<template>
  <in-layout>
    <div slot="list">
      <v-layout class="pa-5" wrap>
        <v-flex xs12 class="mb-3">
          <span class="title">Configurações</span> <span class="caption"> (v0.302)</span>
        </v-flex>
        <v-flex
          xs4
          class="mb-4"
          v-for="(setting, index) in settings"
          :key="index"
        >
          <v-card
            v-if="checkPermission(setting.permission)"
            class="custom-card"
            width="350"
            height="90"
            flat
          >
            <router-link tag="span" :to="{ name: setting.routeName }">
              <v-list two-line>
                <v-list-tile>
                  <v-list-tile-avatar tile>
                    <img :src="setting.icon" alt="">
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ setting.title }}</v-list-tile-title>
                    <v-list-tile-sub-title>{{ setting.subTitle }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </router-link>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import MyDataIcon from '@/assets/my-data-icon.svg';
import EmployeeIcon from '@/assets/employee-icon.svg';
import PendingLicensesIcon from '@/assets/pending-licenses-icon.svg';
import RegisteredLicensesIcon from '@/assets/registered-licenses-icon.svg';
import CompanyIcon from '@/assets/company-icon.svg';
import RegisteredMtrIcon from '@/assets/registered-mtr-icon.svg';
import RegisteredUsersIcon from '@/assets/registered-users-icon.svg';

export default {
  name: 'Settings',
  components: {
    InLayout,
  },
  data() {
    return {
      settings: [
        {
          title: 'Meus Dados', subTitle: 'Dados do Usuário', icon: MyDataIcon, routeName: 'Meus Dados', permission: 'all',
        },
        {
          title: 'Empresas cadastradas', subTitle: 'Entidades cadastradas no sistema', icon: CompanyIcon, routeName: 'Empresas', permission: 'admin',
        },
        {
          title: 'Empresas pendentes', subTitle: 'Solicitações de acesso ao sistema', icon: EmployeeIcon, routeName: 'Empresas Pendentes', permission: 'admin',
        },
        {
          title: 'Licenças cadastradas', subTitle: 'Licenças cadastradas no sistema', icon: RegisteredLicensesIcon, routeName: 'Licenças Cadastradas', permission: 'admin',
        },
        {
          title: 'Licenças pendentes', subTitle: 'Solicitação de validação de licença', icon: PendingLicensesIcon, routeName: 'Licenças Pendentes', permission: 'admin',
        },
        {
          title: 'MTRs cadastrados', subTitle: 'MTRs cadastrados no sistema', icon: RegisteredMtrIcon, routeName: 'MTRs Cadastrados', permission: 'admin',
        },
        {
          title: 'Usuários cadastrados', subTitle: 'Usuários cadastrados no sistema', icon: RegisteredUsersIcon, routeName: 'Usuários', permission: 'admin',
        }
      ],
    };
  },
  methods: {
    checkPermission(permission) {
      return permission === 'all' || this.currentUser.roles.includes(permission);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters['auth/currentUser'];
    },
  },
};
</script>

<style scoped>
  .custom-card {
    cursor: pointer;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0);
  }
  .custom-card:hover {
    border: 1px solid green;
    padding: -1px;
    transition: ease-in-out .1s;
  }
  .v-list {
    background: rgba(0, 0, 0, 0);
  }
</style>
