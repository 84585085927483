var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("in-layout", [
    _c(
      "div",
      { attrs: { slot: "list" }, slot: "list" },
      [
        _c(
          "v-layout",
          { staticClass: "pa-5", attrs: { wrap: "" } },
          [
            _c("v-flex", { staticClass: "mb-3", attrs: { xs12: "" } }, [
              _c("span", { staticClass: "title" }, [_vm._v("Configurações")]),
              _c("span", { staticClass: "caption" }, [_vm._v(" (v0.302)")]),
            ]),
            _vm._l(_vm.settings, function (setting, index) {
              return _c(
                "v-flex",
                { key: index, staticClass: "mb-4", attrs: { xs4: "" } },
                [
                  _vm.checkPermission(setting.permission)
                    ? _c(
                        "v-card",
                        {
                          staticClass: "custom-card",
                          attrs: { width: "350", height: "90", flat: "" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                tag: "span",
                                to: { name: setting.routeName },
                              },
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { "two-line": "" } },
                                [
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-avatar",
                                        { attrs: { tile: "" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: setting.icon,
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(_vm._s(setting.title)),
                                          ]),
                                          _c("v-list-tile-sub-title", [
                                            _vm._v(_vm._s(setting.subTitle)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }